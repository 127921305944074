import React, { useContext, useState } from 'react';
import Link from 'components/customLink';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import { staticLobbyItem } from 'pages/api/static-lobbies';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Loading from 'assets/loading';
import styles from './styles.module.scss';
const GameProvider = dynamic(() => import('./components/GameProvider'), {
  loading: () => <Loading />
});
const GameName = dynamic(() => import('./components/GameName'), {
  loading: () => <Loading />
});
export default function PromoGameCardDefault(props: staticLobbyItem) {
  const {
    provider,
    name,
    displayProvider,
    displayProviderOnHover,
    displayName,
    displayNameOnHover,
    aspectRatio,
    backgroundColor,
    link,
    linkLabel,
    thumbnail,
    promotedThumbnail = '',
    onHoverThumbnail,
    displayNameColor,
    displayNameOnHoverColor,
    displayProviderColor,
    displayProviderOnHoverColor,
    scaleThumbOnHover,
    changeThumbOnHover,
    displayCta,
    displayCtaOnHover,
    ctaBackgroundColor,
    ctaBackgroundColorOnHover,
    ctaColor,
    ctaColorOnHover,
    customLink
  } = props;
  const {
    state,
    appType
  } = useContext(GlobalContext);
  const [mouseIn, setMouseIn] = useState(false);
  const handleMouseEnter = () => {
    if (!mouseIn) setMouseIn(true);
  };
  const handleMouseLeave = () => {
    if (mouseIn) setMouseIn(false);
  };
  return <Link href={link || customLink || ''} style={{
    width: '100%'
  }} data-sentry-element="Link" data-sentry-component="PromoGameCardDefault" data-sentry-source-file="index.tsx">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.wrapper} style={{
      aspectRatio
    }} data-scale-on-hover={!!scaleThumbOnHover}>
        <div className={styles['wrapper-bg']} style={{
        aspectRatio,
        backgroundColor
      }}>
          <img src={thumbnail || promotedThumbnail} alt="name" style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          backfaceVisibility: 'hidden',
          transform: 'translateZ(0)'
        }} />
        </div>
        {changeThumbOnHover && onHoverThumbnail && <img src={onHoverThumbnail} alt="game" style={{
        opacity: mouseIn ? 1 : 0,
        transition: 'all ease-in-out 300ms',
        objectFit: 'cover'
      }} />}
        <GameProvider provider={provider} visible={mouseIn ? !!displayProviderOnHover : !!displayProvider} displayProviderColor={mouseIn ? displayProviderOnHoverColor : displayProviderColor} data-sentry-element="GameProvider" data-sentry-source-file="index.tsx" />
        <GameName name={name} visible={mouseIn ? !!displayNameOnHover : !!displayName} displayNameColor={mouseIn ? displayNameOnHoverColor : displayNameColor} data-sentry-element="GameName" data-sentry-source-file="index.tsx" />
        {linkLabel && <div data-display-cta={!!displayCta} data-display-cta-hover={!!displayCtaOnHover} className={styles.cta} hidden={appType === 'mobile'}>
            <div>
              <button id={`${name}-cta`} data-btn-state="cta" type="button" style={{
            color: ctaColor,
            backgroundColor: ctaBackgroundColor,
            borderColor: ctaBackgroundColor
          }}>
                <Typography size="md" weight={800}>
                  {linkLabel}
                </Typography>
              </button>
              <button data-btn-state="cta-hover" type="button" style={{
            color: ctaColorOnHover,
            backgroundColor: ctaBackgroundColorOnHover,
            borderColor: ctaBackgroundColorOnHover
          }}>
                <Typography size="md" weight={800}>
                  {linkLabel}
                </Typography>
              </button>
            </div>
          </div>}
      </div>
    </Link>;
}