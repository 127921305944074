import Grid from 'components/uiKit/grid';
import React from 'react';
import { useRouter } from 'next/router';
import Typography from 'components/uiKit/typography';
import { MenuItem } from 'components/uiKit/menu/MenuItem';
export default function LeftCta() {
  const {
    asPath
  } = useRouter();
  const menuTextClassNames = '!text-[0.75rem] whitespace-nowrap !font-bold';
  return <Grid wrap="nowrap" horizontalAlgin="flex-end" verticalAlgin="center" className="max-w-[250px] !justify-between h-full" data-sentry-element="Grid" data-sentry-component="LeftCta" data-sentry-source-file="leftCta.tsx">
      <MenuItem href="/kasino" isActive={asPath === '/kasino'} data-sentry-element="MenuItem" data-sentry-source-file="leftCta.tsx">
        <Typography translateGroup="navbar" translateKey="KASINO" color="var(--primary)" className={menuTextClassNames} data-sentry-element="Typography" data-sentry-source-file="leftCta.tsx" />
      </MenuItem>
      <MenuItem href="/live-kasino" isActive={asPath === '/live-kasino'} data-sentry-element="MenuItem" data-sentry-source-file="leftCta.tsx">
        <Typography translateGroup="navbar" translateKey="LIVE-KASINO" color="var(--primary)" className={menuTextClassNames} data-sentry-element="Typography" data-sentry-source-file="leftCta.tsx" />
      </MenuItem>
    </Grid>;
}