export function transformNameToFileName(name: string): string {
  return (
    `${name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with hyphens
    }-logo.svg` // Append '-logo.svg' at the end
  )
}

// 3 suppliers are commented out because they are not supported
// if they are added make sure that they have a image in the public/assets/game-providers
export const ALLOWED_SUPPLIERS = [
  'Pragmatic Play',
  'Hacksaw Gaming',
  'Nolimit City',
  "Play'n Go",
  'NetEnt',
  'Relax Gaming',
  'Evolution Gaming',
  'Novomatic',
  'Big Time Gaming',
  'Red Tiger',
  'Thunderkick',
  'Quickspin',
  'Slotmill',
  'Spinomenal',
  'Blueprint Gaming',
  'Print Studios',
  'Endorphina',
  'Bullshark Games',
  'Backseat Gaming',
  'Raw iGaming',
  // 'Pragmatic Play Live',
  // 'Habanero',
  // 'Booming Games',
]

export const ALLOWED_SUPPLIERS_CODE = [
  'pgp',
  'hcw',
  'nlc',
  'png',
  'ntn',
  'reg',
  'evo',
  'nvm',
  'btg',
  'rtg',
  'tdk',
  'qps',
  'sml',
  'spn',
  'blg',
  'pnt',
  'end',
  'bls',
  'bcs',
  'raw',
]

export const ALLOWED_SUPPLIERS_WITH_CODE = ALLOWED_SUPPLIERS.map((name, i) => ({
  name,
  code: ALLOWED_SUPPLIERS_CODE[i],
}))

export const getSupplierByUrl = (url: string) => {
  return ALLOWED_SUPPLIERS_WITH_CODE.find(({ name, code }) => (
    name.replace(/\s+/g, '-').toLowerCase() === url.toLowerCase() || url.includes(code)
  ))
}

export const getCMSProviderMsgCode = (pathName: string, gameOrSupplierName: string) => {
  if (['/pelivalmistajat/[supplier]', '/games/launch/[gameName]'].includes(pathName)) {
    return `game-provider-${getSupplierByUrl(gameOrSupplierName)?.code}`
  }
  return undefined
}
