import { api } from '../..';
import urls from '../urls';
import { ALLOWED_SUPPLIERS, transformNameToFileName } from './helper';
import { Game, Supplier } from './types';
export async function getLobby(lobbyName: string, mobile: boolean, limit?: number | 'unlimited'): Promise<any> {
  return api.get(`${urls.games.getLobbies}/${lobbyName}?gamesPerCategory=${limit || 20}&mobile=${mobile}`).then(res => res.data).catch(err => null);
}
export async function getCustomGames() {
  return api.get('/api/static-games-customization').then(res => res.data).catch(err => []);
}
export async function getLobbyServerSide(lobbyName: string): Promise<any> {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}${urls.games.getLobbies}/${lobbyName}`);
    if (!response.ok) {
      // If the response status is not 2xx, return an empty array or any other fallback.
      return [];
    }
    const jsonData = await response.json();
    return jsonData || jsonData.data || [];
  } catch (error) {
    return [];
  }
}
export async function getCategoryGames(category: string, mobile: boolean): Promise<any> {
  return api.get(`${urls.games.gamesCategory}/${category}?mobile=${mobile}`).then(res => res.data).catch(err => null);
}
export async function getProviderGames(provider: string, mobile: boolean): Promise<any> {
  return api.get(`${urls.games.gamesProvider}/${provider}?mobile=${mobile}`).then(res => res.data.map((game: any) => game)).catch(err => null);
}
export async function getSupplierGames(supplier: string, mobile: boolean): Promise<any> {
  return api.get(`${urls.games.gamesSupplier}/${supplier}?mobile=${mobile}`).then(res => res.data.map((game: any) => game)).catch(err => null);
}
export async function getLastPlayerGames(mobile: boolean, limit?: number): Promise<any> {
  return api.get(`${urls.games.lastPlayed}?mobile=${mobile}&limit=${limit || 20}`).then(res => res.data).catch(err => null);
}
interface searchGamesInterface {
  search: string;
  params: {
    numberOfGames: number;
    mobile: boolean;
  };
}
export async function searchGames({
  search,
  params
}: searchGamesInterface): Promise<any> {
  return api.get(`${urls.games.search}/${search}`, {
    params
  }).then(res => res.data).catch(err => null);
}
export async function getCategories(mobile: boolean): Promise<any> {
  return api.get(`${urls.games.getCategories}?mobile=${mobile}`, {}).then(res => res.data).catch(err => null);
}
export async function gameInfo(gameCode: string): Promise<any> {
  return api.get(`${urls.games.gameInfo}/${gameCode}`).then(res => res.data).catch(err => null);
}

/**
 * Fetches a list of game suppliers from the API.
 *
 * @returns {Promise<string[]>} A promise that resolves to an array of supplier names.
 * If the request fails or the data is not in the expected format, it returns an empty array.
 */
export async function getSuppliers(supplierName = ''): Promise<Supplier[]> {
  try {
    const response = await api.get(`${urls.games.suppliers}`);
    if (!Array.isArray(response.data)) return [];
    const includedNames = ALLOWED_SUPPLIERS;

    // Filter, sort, and map the suppliers to return objects in the specified order
    const suppliers = response.data.filter(name => includedNames.includes(name)).sort((a, b) => includedNames.indexOf(a) - includedNames.indexOf(b)).map(name => {
      const supplierLogo = transformNameToFileName(name);
      const url = name.replace(/\s+/g, '-').toLowerCase(); // Replace whitespace with dashes and convert to lowercase
      return {
        name,
        supplier_logo: supplierLogo,
        url
      };
    }) as Supplier[];
    if (supplierName) {
      return suppliers.filter(supplier => supplier.name.toLocaleLowerCase().includes(supplierName));
    }
    return suppliers;
  } catch (error) {
    return [];
  }
}
export async function getSuppliersServerSide(): Promise<Supplier[]> {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}${urls.games.suppliers}`);
    const jsonData = await response.json();
    if (!Array.isArray(jsonData)) return [];
    const includedNames = ALLOWED_SUPPLIERS;

    // Filter out excluded suppliers and map to supplier objects
    const suppliers = jsonData.filter((name: string) => includedNames.includes(name)).map((name: string) => {
      const supplierLogo = transformNameToFileName(name);
      return {
        name,
        supplier_logo: supplierLogo
      };
    }) as Supplier[];
    return suppliers;
  } catch (error) {
    return [];
  }
}
export async function getAllGamesServerSide(): Promise<Game[]> {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}${urls.games.search}`);
    const jsonData = await response.json();
    if (!Array.isArray(jsonData)) return [];
    return jsonData;
  } catch (error) {
    return [];
  }
}