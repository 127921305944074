import AppLogo from 'assets/logo/appLogo';
import Link from 'components/customLink';
import Grid from 'components/uiKit/grid';
import Container from 'components/uiKit/grid/container';
import AuthContext from 'context/auth';
import { useRouter } from 'next/router';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import SearchGames from 'sections/searchGames';
import { TopMessage } from 'components/messages/TopMessage';
import { twMerge } from 'tailwind-merge';
import { getCMSProviderMsgCode } from 'utils/services/api/requests/games/helper';
import LeftCta from './components/leftCta/leftCta';
import PrefetchCall from './components/prefetchCall';
import RightCta from './components/rightCta/rightCta';
import { Search } from './components/search/search';
import styles from './styles.module.scss';
import { NavbarContext } from '..';
export default function NavbarDesktop() {
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    filter,
    setFilter
  } = useContext(NavbarContext);
  const {
    pathname,
    query: {
      supplier,
      gameName
    }
  } = useRouter();
  const [isMaintenanceMsgShow, setMaintenanceMsgShow] = useState(false);
  useEffect(() => {
    const checkScroll = () => {
      if (document) {
        const desktopBanner: any = document.getElementById('banner-desktop-wrapper');
        const desktopBannerBounds = desktopBanner?.getBoundingClientRect();
        if (desktopBannerBounds && desktopBannerBounds.y < -desktopBannerBounds.height) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);
  useEffect(() => {
    if (filter) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }
    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [filter]);
  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  const messageSlug = isAuthenticated ? getCMSProviderMsgCode(pathname, (supplier ?? gameName) as string) : undefined;
  return <>
      <Grid className={twMerge(styles.nav, isMaintenanceMsgShow ? 'bg-secondary' : pathname === '/' && !isAuthenticated && !filter ? 'bg-primary' : 'bg-[var(--navbar-bg)]')} data-fix={isFixed || Boolean(filter)} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <TopMessage messageSlug={messageSlug} onMessageShow={value => setMaintenanceMsgShow(value as boolean)} data-sentry-element="TopMessage" data-sentry-source-file="index.tsx" />
        {isAuthenticated && <PrefetchCall />}
        <Grid verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid className={twMerge(styles['bg-navbar'], 'h-full')} padding={['ps-4', 'pe-5']} verticalAlgin="center" wrap="nowrap" horizontalAlgin="space-between" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid wrap="nowrap" verticalAlgin="center" className="pr-2 h-full" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Link href="/" onClick={() => setFilter('')} className="flex justify-start items-center pr-8" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <AppLogo color="blue" width="114" height="30" data-sentry-element="AppLogo" data-sentry-source-file="index.tsx" />
              </Link>
              <Grid verticalAlgin="center" className="!justify-start h-full" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <LeftCta data-sentry-element="LeftCta" data-sentry-source-file="index.tsx" />
              </Grid>
            </Grid>
            <Search handleFilterChange={handleFilterChange} filter={filter} setFilter={setFilter} data-sentry-element="Search" data-sentry-source-file="index.tsx" />

            <RightCta data-sentry-element="RightCta" data-sentry-source-file="index.tsx" />
          </Grid>
        </Grid>
        <Grid hidden={!filter} className={!!filter && styles['search-area']} padding={['p-3']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">{filter && <SearchGames filter={filter} />}</Container>
        </Grid>
      </Grid>
      <div style={{
      height: '6.6rem',
      width: '100%'
    }} hidden={!isFixed} />
    </>;
}