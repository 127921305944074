import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React from 'react';
import { ProgressBar } from 'components/progressBar';
import useXpBonusProgress from 'utils/customHooks/useXpProgress';
export default function ProgressXP() {
  const {
    data,
    progress
  } = useXpBonusProgress();
  return <Grid data-sentry-element="Grid" data-sentry-component="ProgressXP" data-sentry-source-file="progress.tsx">
      <Grid horizontalAlgin="space-between" padding={['pb-2']} data-sentry-element="Grid" data-sentry-source-file="progress.tsx">
        <Typography weight={300} color="#fff" data-sentry-element="Typography" data-sentry-source-file="progress.tsx">
          {data.name}
        </Typography>
        <Typography weight={200} color="#fff" data-sentry-element="Typography" data-sentry-source-file="progress.tsx">
          {progress}
          %
        </Typography>
      </Grid>
      <ProgressBar progress={progress} border="secondary" data-sentry-element="ProgressBar" data-sentry-source-file="progress.tsx" />
    </Grid>;
}