import React from 'react';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import PromoGameCard from 'components/cards/promoGameCard';
const gridSizes = [{
  sm: '50%',
  md: '33.33%',
  lg: '16.66%'
}, {
  sm: '100%',
  md: '66.66%',
  lg: '33.33%'
}, {
  sm: '100%',
  md: '66.66%',
  lg: '33.33%'
}].map(size => ({
  sm: `calc(${size.sm} - 0.5rem)`,
  md: `calc(${size.md} - 2rem / 3)`,
  lg: `calc(${size.lg} - 5rem / 6)`
}));
interface GamesResultProps {
  color?: '#fff' | 'var(--primary)';
  games: Array<any>;
}
const GamesResult: React.FC<GamesResultProps> = ({
  color = '#fff',
  games
}) => {
  const {
    appType
  } = React.useContext(GlobalContext);
  return <Grid gap={appType === 'mobile' ? '0.5rem' : '1rem'} id="gameSearch" style={{
    overflowX: 'auto'
  }} hidden={!games.length} data-sentry-element="Grid" data-sentry-component="GamesResult" data-sentry-source-file="GamesResult.tsx">
      <Grid verticalAlgin="center" horizontalAlgin="center" style={{
      margin: '0 auto'
    }} padding={['pt-2', 'pb-2']} data-sentry-element="Grid" data-sentry-source-file="GamesResult.tsx">
        <Typography translateGroup="search-area" color={appType === 'mobile' ? color : 'var(--primary)'} translateKey={`Löysimme haullasi ${games.length} peliä`} size={appType === 'mobile' ? 'md' : 'xl'} className="!uppercase !font-extrabold xs:!mt-5 md:!mt-0" data-sentry-element="Typography" data-sentry-source-file="GamesResult.tsx" />
      </Grid>
      <Grid padding={['pe-3', 'ps-3']} gap="1rem" verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="GamesResult.tsx">
        {games.map((game, index) => {
        return <Grid key={index} responsiveWidth={gridSizes[game.size - 1]}>
              <PromoGameCard {...game} aspectRatio="3 / 4" displayNameOnHover={false} displayName={false} displayProvider={false} />
            </Grid>;
      })}
      </Grid>
    </Grid>;
};
export default GamesResult;