import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React from 'react';
interface EmptyResultProps {
  color?: string;
}
const EmptyResult: React.FC<EmptyResultProps> = ({
  color
}) => {
  return <Grid width="100%" verticalAlgin="center" horizontalAlgin="center" wrap="wrap" style={{
    color
  }} data-sentry-element="Grid" data-sentry-component="EmptyResult" data-sentry-source-file="EmptyResult.tsx">
      <Grid verticalAlgin="center" horizontalAlgin="center" margin={['mt-5', 'mb-5']} data-sentry-element="Grid" data-sentry-source-file="EmptyResult.tsx">
        <Typography translateGroup="searchGame" weight={700} className="xs:!text-2xl xs:!leading-6 lg:!text-96 lg:!leading-[5.6rem] text-center" translateKey="Pahus, emme löydä etsimääsi." data-sentry-element="Typography" data-sentry-source-file="EmptyResult.tsx" />
      </Grid>
      <Grid verticalAlgin="center" horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="EmptyResult.tsx">
        <Typography translateGroup="searchGame" className="xs:!text-12 lg:!text-2xl text-center block w-full" translateKey="Mutta etsivä löytää." data-sentry-element="Typography" data-sentry-source-file="EmptyResult.tsx" />
        <Typography translateGroup="searchGame" className="xs:!text-12 lg:!text-2xl text-center block w-full" translateKey="Tarkista hakusanasi tai kokeile jotain toista." data-sentry-element="Typography" data-sentry-source-file="EmptyResult.tsx" />
      </Grid>
    </Grid>;
};
export default EmptyResult;