import AuthContext from 'context/auth';
import { useContext } from 'react';
import { TopMessage } from 'components/messages/TopMessage';
import { useRouter } from 'next/router';
import { getCMSProviderMsgCode } from 'utils/services/api/requests/games/helper';
import MobileNavbarLoggedIn from './loggedIn';
import MobileNavbarLoggedOut from './loggedOut';
export default function NavbarMobile() {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    pathname,
    query: {
      supplier,
      gameName
    }
  } = useRouter();
  const messageSlug = isAuthenticated ? getCMSProviderMsgCode(pathname, (supplier ?? gameName) as string) : undefined;
  return <>
      {!['/', '/tuohirulla'].includes(pathname) && <TopMessage messageSlug={messageSlug} />}
      {isAuthenticated ? <MobileNavbarLoggedIn /> : <MobileNavbarLoggedOut />}
    </>;
}