'use client';

import React, { useContext, useMemo } from 'react';
import Grid from 'components/uiKit/grid';
import AuthContext from 'context/auth';
import DialogContext, { externalDialogCall } from 'context/dialog';
import GlobalContext from 'context/global';
import { useRouter } from 'next/router';
import { useBonuses } from 'utils/customHooks/useBonuses';
import UserIcon from 'assets/icons/userIcon';
import GiftIcon from 'assets/icons/giftIcon';
import KasinoIcon from 'assets/icons/kasinoIcon';
import LiveKasinoIcon from 'assets/icons/liveKasino';
import Separate from 'components/uiKit/separate';
import BullHornIcon from 'assets/icons/bullhornIcon';
import WithdrawMenuIcon from 'assets/icons/withdrawMenuIcon';
import ChatIcon from 'assets/icons/chatIcon';
import LogoutIcon from 'assets/icons/logoutIcon';
import WheelIcon from 'assets/icons/wheelIcon';
import { useWithdrawalCheck } from 'utils/customHooks/useWithdrawalCheck';
import { useProfile } from 'utils/customHooks/useProfile';
import { NavLink } from './navLink';
import { Notification } from './notification';
interface Props {
  setSection: Function;
}
interface MenuItem {
  uri: string;
  label: string;
  icon?: React.ReactElement;
  action: any;
  hidden?: boolean;
  notification?: React.ReactElement;
}
interface SeparateItem {
  type: 'separate';
}
export default function Links(props: Props) {
  const {
    setSection
  } = props;
  const {
    push
  } = useRouter();
  const {
    appType,
    handlerOpenZendesk
  } = useContext(GlobalContext);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    logout
  } = useContext(AuthContext);
  const {
    removeAllDialog
  } = useContext(DialogContext);
  const {
    count: bonusCount
  } = useBonuses();
  const {
    handleWithdrawalClick,
    isLoading
  } = useWithdrawalCheck();
  const {
    profile
  } = useProfile();
  const handlerNavPage = (uri: string) => {
    push(uri);
    externalDialogCall.removeAllDialog();
  };
  const handleSignOut = () => {
    logout();
    removeAllDialog();
  };
  const handlerChat = () => {
    const name = profile ? [profile.firstName, profile.lastName].join(' ') : '';
    handlerOpenZendesk(name);
    externalDialogCall.removeAllDialog();
  };
  const menuItems: Array<MenuItem | SeparateItem> = useMemo(() => [{
    uri: '/kasino',
    label: 'Kasino',
    icon: <KasinoIcon />,
    action: handlerNavPage,
    hidden: false
  }, {
    uri: '/live-kasino',
    label: 'Live-kasino',
    icon: <LiveKasinoIcon />,
    action: handlerNavPage,
    hidden: false
  }, {
    type: 'separate'
  }, {
    uri: '',
    label: 'Palkinnot',
    icon: <GiftIcon />,
    action: () => setSection('rewards'),
    notification: <Notification value={bonusCount} />,
    hidden: !isAuthenticated
  }, {
    uri: '',
    label: 'Kampanjat',
    icon: <BullHornIcon />,
    action: () => setSection('campaigns'),
    hidden: false
  }, {
    uri: '/tuohirulla',
    label: 'Tuohirulla',
    icon: <WheelIcon />,
    action: handlerNavPage,
    hidden: false
  }, {
    uri: '/profile',
    label: 'Tilini',
    icon: <UserIcon />,
    action: appType === 'mobile' ? () => setSection('menu-profile') : handlerNavPage,
    hidden: !isAuthenticated
  }, {
    uri: '/cashier/withdraw',
    label: 'Nosta',
    icon: <WithdrawMenuIcon />,
    action: handleWithdrawalClick,
    hidden: !isAuthenticated
  }, {
    uri: '',
    label: 'Tuki',
    icon: <ChatIcon />,
    action: handlerChat,
    hidden: false
  }, {
    uri: '',
    label: 'logout',
    icon: <LogoutIcon />,
    action: handleSignOut,
    hidden: !isAuthenticated
  }], [isAuthenticated, handleSignOut, handlerChat, handlerNavPage]);
  return <Grid className="xs:mb-5 lg:mb-8 desktop:!gap-8 xs:!gap-4" data-sentry-element="Grid" data-sentry-component="Links" data-sentry-source-file="links.tsx">
      {menuItems.map((item: MenuItem | SeparateItem, index: number) => {
      if ((item as SeparateItem).type === 'separate') {
        return <Separate key={index} />;
      }
      const {
        uri,
        label,
        action,
        hidden,
        notification,
        icon
      } = item as MenuItem;
      return <NavLink key={index} uri={uri} label={label} handleNavFunction={action} hidden={hidden} icon={icon} notification={notification} />;
    })}
      {(isAuthenticated || appType === 'desktop') && <Separate />}
    </Grid>;
}