import React from 'react';
import SupplierCard from 'components/supplierCard';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import { Supplier } from 'utils/services/api/requests/games/types';
interface SearchSuppliersProps {
  color?: '#fff' | 'var(--primary)';
  suppliers: Supplier[];
}
const SearchSuppliers: React.FC<SearchSuppliersProps> = ({
  color = '#fff',
  suppliers
}) => {
  const {
    appType
  } = React.useContext(GlobalContext);
  return <Grid gap={appType === 'mobile' ? '0.5rem' : '1rem'} id="searchSupplier" style={{
    overflowX: 'auto'
  }} hidden={!suppliers?.length} data-sentry-element="Grid" data-sentry-component="SearchSuppliers" data-sentry-source-file="SuppliersResult.tsx">
      <Grid verticalAlgin="center" horizontalAlgin="center" style={{
      margin: '0 auto'
    }} padding={['pt-2', 'pb-2']} data-sentry-element="Grid" data-sentry-source-file="SuppliersResult.tsx">
        <Typography translateGroup="game-carousel" color={appType === 'mobile' ? color : 'var(--primary)'} translateKey="pelivalmistajat" size={appType === 'mobile' ? 'md' : 'xl'} className="!uppercase !font-extrabold xs:!mt-5 md:!mt-0" data-sentry-element="Typography" data-sentry-source-file="SuppliersResult.tsx" />
      </Grid>
      <Grid padding={['pe-3', 'ps-3']} gap="1rem" className="mb-10 xs:!justify-center md:!justify-start" data-sentry-element="Grid" data-sentry-source-file="SuppliersResult.tsx">
        {suppliers?.map((supplier: Supplier, index: number) => <div className="w-auto" key={index}>
            <SupplierCard supplier={supplier} />
          </div>)}
      </Grid>
    </Grid>;
};
export default SearchSuppliers;