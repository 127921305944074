import GlobalContext from 'context/global';
import React from 'react';
import { useQuery } from 'react-query';
import { parseGames } from 'utils/functions/games';
import { getSuppliers, searchGames } from 'utils/services/api/requests/games';
import { staticLobbyItem } from 'pages/api/static-lobbies';
import { Supplier } from 'utils/services/api/requests/games/types';
import SkeletonCard from 'components/cards/skeletonCard';
import EmptyResult from './components/EmptyResult';
import SuppliersResult from './components/SuppliersResult';
import GamesResult from './components/GamesResult';
interface Props {
  filter: string;
  color?: 'var(--primary)' | '#fff';
}
export default function SearchGames({
  filter,
  color = '#fff'
}: Props) {
  const {
    appType
  } = React.useContext(GlobalContext);
  const isMobile = ['mobile', 'tablet'].includes(appType);
  const {
    data: filterResponse,
    isLoading
  } = useQuery(['games-search', filter], () => searchGames({
    search: filter,
    params: {
      numberOfGames: 50,
      mobile: isMobile
    }
  }), {
    enabled: filter.length >= 2
  });
  const {
    data: supplierList,
    isLoading: isSuppliersLoading
  } = useQuery<Supplier[]>(['provider-search', filter], () => getSuppliers(filter), {
    enabled: filter.length >= 2
  });
  const originalGames = filterResponse?.map((item: any) => ({
    game: item
  })) || [];
  const parsedList = parseGames({
    originalGames,
    defaultSize: () => 1
  }).sort((a: staticLobbyItem, b: staticLobbyItem) => {
    const startWithFilter = (str: any) => str.name.toLowerCase().startsWith(filter);
    if (startWithFilter(a) && !startWithFilter(b)) {
      return -1;
    }
    if (!startWithFilter(a) && startWithFilter(b)) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
  if (filter.length < 2) return null;
  if (filter.length === 2 && (isLoading || isSuppliersLoading)) {
    return <div className="flex flex-row gap-3 mt-5 w-full xs:flex-wrap justify-center">
        {Array.from({
        length: 6
      }).map((_, index) => <div key={index} className="xs:w-[calc(100%/2-12px)] sm:w-[calc(100%/3-24px)] md:w-[calc(100%/4-36px)] lg:w-[calc(100%/6-60px)]"><SkeletonCard /></div>)}
      </div>;
  }
  if (!(parsedList.length || supplierList?.length)) {
    return <EmptyResult color={appType === 'mobile' ? color : 'var(--primary)'} />;
  }
  return <>
      <SuppliersResult color={color} suppliers={supplierList as Supplier[]} data-sentry-element="SuppliersResult" data-sentry-source-file="index.tsx" />
      <GamesResult color={appType === 'mobile' ? color : 'var(--primary)'} games={parsedList as Array<any>} data-sentry-element="GamesResult" data-sentry-source-file="index.tsx" />
    </>;
}