import MenuIcon from 'assets/icons/menuIcon';
import Link from 'components/customLink';
import { ProgressBar } from 'components/progressBar';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import UserBalanceOnlyReal from 'components/userBalance/onlyReal';
import AuthContext from 'context/auth';
import DialogContext from 'context/dialog';
import GlobalContext from 'context/global';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import useXpBonusProgress from 'utils/customHooks/useXpProgress';
import { useBonuses } from 'utils/customHooks/useBonuses';
import { MenuItem } from 'components/uiKit/menu/MenuItem';
import FloatMenu from '../floatMenu';
import style from './right.cta.module.scss';
export default function RightCta() {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    asPath,
    pathname
  } = useRouter();
  const {
    progress,
    data,
    isLoading: isLoadingXp
  } = useXpBonusProgress();
  const {
    appType
  } = useContext(GlobalContext);
  const {
    count: bonusCount,
    bonuses
  } = useBonuses();
  const {
    displayDialog,
    removeDialog
  } = useContext(DialogContext);
  function handleDisplayDialog() {
    displayDialog({
      dialogId: 'FLOAT-MENU',
      content: <FloatMenu closeDialog={() => removeDialog('FLOAT-MENU')} />,
      dialogProps: {
        anchor: 'right',
        dialogStylesBox: {
          borderRadius: 0
        },
        displayClose: false
      }
    });
  }
  return <Grid wrap="nowrap" gap="1rem" horizontalAlgin="flex-end" verticalAlgin="center" padding="ps-2" className="h-full" data-sentry-element="Grid" data-sentry-component="RightCta" data-sentry-source-file="rightCta.tsx">
      {isAuthenticated ? <Grid wrap="nowrap" horizontalAlgin="flex-end" verticalAlgin="center">
          <Grid wrap="nowrap" verticalAlgin="center" horizontalAlgin="space-between" className="max-w-[400px] md:!gap-2">
            <Grid hidden={asPath.includes('/games/launch/')} verticalAlgin="flex-start" horizontalAlgin="flex-start" className="h-8 max-w-[108px]">
              <Typography size="sm" translateGroup="global" translateKey={data.name} className={style['loyalty-level']} />
              {!isLoadingXp && <ProgressBar progress={progress} border="primary" />}
            </Grid>
            <Grid verticalAlgin="flex-end" wrap="wrap" className="!w-max">
              <Grid hidden={pathname === '/games/launch/[gameName]'} className="!flex-col">
                <Typography translateGroup="global" translateKey="Balance:" className="tracking-widest !text-xs whitespace-nowrap" />
                <Grid width="max-content" className="text-primary text-sm font-bold whitespace-nowrap">
                  <UserBalanceOnlyReal />
                </Grid>
              </Grid>
            </Grid>
            <Grid width="fit-content" gap="0.68rem" horizontalAlgin="flex-end">
              <Grid horizontalAlgin="flex-end" hidden={!asPath.includes('/games/launch/')} width="fit-content">
                <Link href="/cashier/deposit" className="w-fit">
                  <Button block id="nosta-cta" color="yellow">
                    <Typography translateGroup="global" translateKey="TALLETA" className="tracking-widest uppercase !text-sm !font-bold" />
                  </Button>
                </Link>
              </Grid>

              <Grid horizontalAlgin="flex-end" hidden={asPath.includes('/games/launch/')} width="fit-content">
                <Link href="/cashier/deposit">
                  <Button id="navbar-float-menu-cta" color="yellow" className="text-sm h-[1.9rem] max-h-[30px] whitespace-nowrap w-[6.8rem]">
                    <Typography translateGroup="global" translateKey="TALLETA" className="tracking-widest uppercase !text-sm !font-bold whitespace-nowrap" />
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid width="fit-content" verticalAlgin="center" horizontalAlgin="center" padding="ps-5" height="100%" style={{
        cursor: 'pointer',
        position: 'relative'
      }} onClick={() => handleDisplayDialog()}>
            {bonusCount > 0 && <span style={{
          position: 'absolute',
          top: '-15px',
          right: '-10px'
        }}>
                <Grid verticalAlgin="center" horizontalAlgin="center" style={{
            borderRadius: '50%',
            width: '1.2rem',
            height: '1.2rem',
            background: 'var(--currency)'
          }}>
                  <Typography translateGroup="notification" translateKey={`${bonusCount}`} color="var(--primary)" size="sm" />
                </Grid>
              </span>}
            <MenuIcon />
          </Grid>
        </Grid> : <Grid horizontalAlgin="flex-end" gap={appType === 'tablet' ? '1rem' : '3.4rem'} wrap="nowrap" verticalAlgin="center" className="h-full">
          <MenuItem href="/login">
            <Typography translateGroup="global" translateKey="login" className="!text-[0.75rem] whitespace-nowrap !font-bold uppercase" color="var(--primary)" />
          </MenuItem>
          <Grid width="max-content" verticalAlgin="center" horizontalAlgin="center">
            <Link href="/cashier/deposit">
              <Button id="deposit" color="yellow">
                <Typography translateGroup="navbar" translateKey="Talleta ja Pelaa" size="sm" color="var(--primary)" weight={700} />
              </Button>
            </Link>
          </Grid>
          <Grid width="fit-content" verticalAlgin="center" horizontalAlgin="center" height="100%" style={{
        cursor: 'pointer'
      }} onClick={() => handleDisplayDialog()}>
            <MenuIcon />
          </Grid>
        </Grid>}
    </Grid>;
}