import HamburgerMenuIcon from 'assets/icons/mobileHamburguerIcon';
import AppLogo from 'assets/logo/appLogo';
import Link from 'components/customLink';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import CustomLoginForm from 'layouts/userForms/customLogin/loginForm';
import CampaignSection from 'sections/campaigns';
import DepositSection from 'sections/deposit';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import Button from 'components/uiKit/buttons';
import HamburgerMenu from 'sections/Menu';
import styles from './styles.module.scss';
import { useLoggedOutMobileMenu } from './useLoggedOutMobileMenu';
export default function MobileNavbarLoggedOut() {
  const {
    menuRef,
    isFixed,
    url,
    section,
    setSection,
    isAuthenticated,
    isSectionIncluded,
    asPath,
    toggleMenuSection
  } = useLoggedOutMobileMenu();
  return <>
      <div className={twMerge('bg-[rgba(255,255,255,0.8)] w-full h-full fixed top-0 left-0 right-0 bottom-0 z-[98] transition-opacity duration-300 ease-in', section !== 'nav' ? 'opacity-100 visible' : 'opacity-0 invisible')} />
      <Grid id="mobile-menu" data-section={section} className={styles.wrapper} gap="1rem" verticalAlgin="flex-start" padding={['ps-3', 'pe-3']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid data-is-fixed={isFixed || section !== 'nav'} className={styles.nav} horizontalAlgin="space-between" verticalAlgin="center" gap="0.5rem" height="66px" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <span ref={menuRef} style={{
          flex: 1
        }}>
            <Grid horizontalAlgin="space-between" verticalAlgin="center" wrap="nowrap" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Grid width="fit-content" gap="2.5rem" verticalAlgin="center" wrap="nowrap" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Link href="/" aria-label="to-home" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                  <AppLogo color="white" height="30px" width="100px" data-sentry-element="AppLogo" data-sentry-source-file="index.tsx" />
                </Link>
                {!isSectionIncluded && <Grid verticalAlgin="center" horizontalAlgin="center" width={80} style={{
                color: '#fff'
              }}>
                    <Typography translateGroup="nav-bar-menu-title" translateKey={section === 'campaigns' ? 'KAMPANJAT' : section} weight={700} color="#fff" style={{
                  textTransform: 'uppercase'
                }} />
                  </Grid>}
              </Grid>
              <Grid width="fit-content" wrap="nowrap" gap="0.625rem" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                {!isAuthenticated && ['nav', 'menu'].includes(section) && <Grid width="fit-content" gap="0.625rem" verticalAlgin="center" horizontalAlgin="center" wrap="nowrap">
                    <Link href={url}>
                      <Button id="login-cta" color="outline-white">
                        <Typography translateGroup="global" weight={700} translateKey="login" style={{
                      textTransform: 'capitalize'
                    }} />
                      </Button>
                    </Link>
                    <Link href="/cashier/deposit" style={{
                  width: 'fit-content'
                }}>
                      <Button id="talleta" color="yellow">
                        <Typography translateGroup="global" translateKey="Talleta" className="!font-bold" />
                      </Button>
                    </Link>
                  </Grid>}
                <Grid width={section === 'nav' ? '1.5rem' : '1rem'} height="100%" verticalAlgin="center" horizontalAlgin="center" onClick={toggleMenuSection} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                  <HamburgerMenuIcon isOpen={section !== 'nav'} data-sentry-element="HamburgerMenuIcon" data-sentry-source-file="index.tsx" />
                </Grid>
              </Grid>
            </Grid>
          </span>
        </Grid>

        {section !== 'nav' && <Grid className={twMerge(styles.content, section === 'menu' && 'xs:!content-end')}>
            {section === 'menu' && <HamburgerMenu section={section} setSection={setSection} />}
            {section === 'login' && <CustomLoginForm originUrl={asPath} />}
            {section === 'deposit-section' && <DepositSection section={section} setSection={setSection} />}
            {section === 'campaigns' && <CampaignSection />}
          </Grid>}
      </Grid>
    </>;
}